import {IVariety} from "../../../classes/all";

enum VarietyDashboardActionTypes {   
    SET_CURRENT_VARIETY = "@@varietyDashboard/SET_CURRENT_VARIETY",
    SET_VARIETY_LIST = "@@varietyDashboard/SET_VARIETY_LIST"
}

export interface VarietyDashboardStateTypes {
    currentVariety: IVariety;
    varietyList: IVariety[];
}

export {VarietyDashboardActionTypes}