import {
    IActiveModal,
    IAddress,
    IOrderVendor
} from "../../../classes/all";

enum OrderVendorDashboardActionTypes {
    SET_ACTIVE_MODAL_ID = "@@orderVendorDashboard/SET_ACTIVE_MODAL_ID",
    SET_CURRENT_ADDRESS = "@@orderVendorDashboard/SET_CURRENT_ADDRESS",
    SET_ORDER_VENDOR_LIST = "@@orderVendorDashboard/SET_ORDER_VENDOR_LIST",
    SET_CURRENT_ORDER_VENDOR = "@@orderVendorDashboard/SET_CURRENT_ORDER_VENDOR",
}

export interface OrderVendorDashboardStateTypes {
    activeModalId: IActiveModal;
    currentAddress: IAddress;
    orderVendorList: IOrderVendor[];
    currentOrderVendor: IOrderVendor;
}

export {OrderVendorDashboardActionTypes}