import {IAgent} from "../../../classes/all";

enum AgentDashboardActionTypes {
    SET_AGENT_LIST = "@@agentDashboard/SET_AGENT_LIST",
    SET_AGENT_CACHE = "@@agentDashboard/SET_AGENT_CACHE"
}

export interface AgentDashboardStateTypes {
    agentList: IAgent[];
    agentCache: {
        [key: number]: IAgent
    };
}

export {AgentDashboardActionTypes}