import {OrderDashboardActionTypes, OrderDashboardStateTypes} from "./constants";
import {
    IActiveModal, 
    IOrder, 
    IOrderStep,
    IOrderStepFlow,
    IOrderStepType,
    IOrderStepVendorAssignment,
    ISeller, 
    IBuyer
} from "../../../classes/all";
import {OrderDashboardActionType} from "./actions";

const INIT_STATE: OrderDashboardStateTypes = {
    activeModalId: {} as IActiveModal, 
    currentSeller: {} as ISeller,
    currentBuyer: {} as IBuyer,
    orderList: [] as IOrder[],
    currentOrder: {} as IOrder,
    orderStepList: [] as IOrderStep[],
    currentOrderStep: {} as IOrderStep,
    orderStepFlowList: [] as IOrderStepFlow[],
    orderStepFlowCache: {} as {[key: number]: IOrderStepFlow[]},
    currentOrderStepFlow: {} as IOrderStepFlow,
    orderStepTypeList: [] as IOrderStepType[],
    currentOrderStepType: {} as IOrderStepType,
    orderStepVendorAssignmentList: [] as IOrderStepVendorAssignment[],
    currentOrderStepVendorAssignment: {} as IOrderStepVendorAssignment,  
};

const OrderDashboard = (
    state: OrderDashboardStateTypes = INIT_STATE,
    action: OrderDashboardActionType<string>
) => {
    switch (action.type) {
        case OrderDashboardActionTypes.SET_ACTIVE_MODAL_ID:
            return {
                ...state,
                activeModalId: action.payload,
            };
        case OrderDashboardActionTypes.SET_CURRENT_SELLER:
            return {
                ...state,
                currentSeller: action.payload,
            };
        case OrderDashboardActionTypes.SET_CURRENT_BUYER:
            return {
                ...state,
                currentBuyer: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_LIST:
            return {
                ...state,
                orderList: action.payload,
            };
        case OrderDashboardActionTypes.SET_CURRENT_ORDER:
            return {
                ...state,
                currentOrder: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP_LIST:
            return {
                ...state,
                orderStepList: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP:
            return {
                ...state,
                currentOrderStep: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP_FLOW_LIST:
            return {
                ...state,
                orderStepFlowList: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP_FLOW:
            return {
                ...state,
                currentOrderStepFlow: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP_FLOW_CACHE:
            return {
                ...state,
                orderStepFlowCache: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP_TYPE_LIST:
            return {
                ...state,
                orderStepTypeList: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP_TYPE:
            return {
                ...state,
                currentOrderStepType: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP_VENDOR_ASSIGNMENT_LIST:
            return {
                ...state,
                orderStepVendorAssignmentList: action.payload,
            };
        case OrderDashboardActionTypes.SET_ORDER_STEP_VENDOR_ASSIGNMENT:
            return {
                ...state,
                currentOrderStepVendorAssignment: action.payload,
            };
        
        default:
            return state;
    }
}

export default OrderDashboard;