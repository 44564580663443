
import {AgentDashboardActionTypes, AgentDashboardStateTypes} from "./constants";

import {IAgent} from "../../../classes/all";

import {AgentDashboardActionType} from "./actions";

const INIT_STATE: AgentDashboardStateTypes = {
    agentList: [] as IAgent[],
    agentCache: {} as {
        [key: number]: IAgent
    }
};

const AgentDashboard = (
    state: AgentDashboardStateTypes = INIT_STATE,
    action: AgentDashboardActionType<string>
) => {
    switch (action.type) {
        case AgentDashboardActionTypes.SET_AGENT_LIST:
            return {
                ...state,
                agentList: action.payload,
            };
        case AgentDashboardActionTypes.SET_AGENT_CACHE:
            return {
                ...state,
                agentCache: action.payload,
            };
        default:
            return state;
    }
}

export default AgentDashboard;