import {IActiveModal, IProduct} from "../../../classes/all";

enum ProductDashboardActionTypes {
    SET_ACTIVE_MODAL_ID = "@@productDashboard/SET_ACTIVE_MODAL_ID",
    SET_CURRENT_PRODUCT = "@@productDashboard/SET_CURRENT_PRODUCT",
    SET_PRODUCT_LIST = "@@productDashboard/SET_PRODUCT_LIST"
}

export interface ProductDashboardStateTypes {
    activeModalId: IActiveModal;
    currentProduct: IProduct;
    productList: IProduct[];
}

export {ProductDashboardActionTypes}