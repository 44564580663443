import {IActiveModal, IBuyer, IAddress} from "../../../classes/all";

enum BuyerDashboardActionTypes {
    SET_ACTIVE_MODAL_ID = "@@buyerDashboard/SET_ACTIVE_MODAL_ID",
    SET_CURRENT_BUYER = "@@buyerDashboard/SET_CURRENT_BUYER",
    SET_CURRENT_ADDRESS = "@@buyerDashboard/SET_CURRENT_ADDRESS",
    SET_BUYER_LIST = "@@buyerDashboard/SET_BUYER_LIST",
    SET_BUYER_CACHE = "@@buyerDashboard/SET_BUYER_CACHE"
}

export interface BuyerDashboardStateTypes {
    activeModalId: IActiveModal;
    currentBuyer: IBuyer;
    currentAddress: IAddress;
    buyerList: IBuyer[];
    buyerCache: {
        [key: number]: IBuyer
    };
}

export {BuyerDashboardActionTypes}