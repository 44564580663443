
import {SellerDashboardActionTypes, SellerDashboardStateTypes} from "./constants";

import {ISeller, IAddress, IGovernmentRegistration, IActiveModal} from "../../../classes/all";

import {SellerDashboardActionType} from "./actions";

const INIT_STATE: SellerDashboardStateTypes = {
    activeModalId: {} as IActiveModal, 
    currentSeller: {} as ISeller,
    currentAddress: {} as IAddress,
    currentGovernmentRegistration: {} as IGovernmentRegistration,
    sellerList: [] as ISeller[],
    sellerCache: {} as {
        [key: number]: ISeller
    }
};

const SellerDashboard = (
    state: SellerDashboardStateTypes = INIT_STATE,
    action: SellerDashboardActionType<string>
) => {
    switch (action.type) {
        case SellerDashboardActionTypes.SET_ACTIVE_MODAL_ID:
            return {
                ...state,
                activeModalId: action.payload,
            };
        case SellerDashboardActionTypes.SET_CURRENT_SELLER:
            return {
                ...state,
                currentSeller: action.payload,
            };
        case SellerDashboardActionTypes.SET_CURRENT_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload,
            };
        case SellerDashboardActionTypes.SET_CURRENT_GOVERNMENT_REGISTRATION:
            return {
                ...state,
                currentGovernmentRegistration: action.payload,
            };
        case SellerDashboardActionTypes.SET_SELLER_LIST:
            return {
                ...state,
                sellerList: action.payload,
            };
        case SellerDashboardActionTypes.SET_SELLER_CACHE:
            return {
                ...state,
                sellerCache: action.payload,
            };
        default:
            return state;
    }
}

export default SellerDashboard;