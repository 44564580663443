import {ISeller, IAddress, IGovernmentRegistration, IActiveModal} from "../../../classes/all";

enum SellerDashboardActionTypes {
    SET_ACTIVE_MODAL_ID = "@@sellerDashboard/SET_ACTIVE_MODAL_ID",
    SET_CURRENT_SELLER = "@@sellerDashboard/SET_CURRENT_SELLER",
    SET_CURRENT_ADDRESS = "@@sellerDashboard/SET_CURRENT_ADDRESS",
    SET_CURRENT_GOVERNMENT_REGISTRATION = "@@sellerDashboard/SET_CURRENT_GOVERNMENT_REGISTRATION",
    SET_SELLER_LIST = "@@sellerDashboard/SET_SELLER_LIST",
    SET_SELLER_CACHE = "@@sellerDashboard/SET_SELLER_CACHE"
}

export interface SellerDashboardStateTypes {
    activeModalId: IActiveModal;
    currentSeller: ISeller;
    currentAddress: IAddress;
    currentGovernmentRegistration: IGovernmentRegistration;
    sellerList: ISeller[];
    sellerCache: {
        [key: number]: ISeller
    };
}

export {SellerDashboardActionTypes}