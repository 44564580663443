import {ProductDashboardActionTypes, ProductDashboardStateTypes} from "./constants";
import {IProduct, IActiveModal} from "../../../classes/all";
import {ProductDashboardActionType} from "./actions";

const INIT_STATE: ProductDashboardStateTypes = {
    activeModalId: {} as IActiveModal, 
    currentProduct: {} as IProduct,
    productList: [] as IProduct[],
};

const ProductDashboard = (
    state: ProductDashboardStateTypes = INIT_STATE,
    action: ProductDashboardActionType<string>
) => {
    switch (action.type) {
        case ProductDashboardActionTypes.SET_ACTIVE_MODAL_ID:
            return {
                ...state,
                activeModalId: action.payload,
            };
        case ProductDashboardActionTypes.SET_CURRENT_PRODUCT:
            return {
                ...state,
                currentProduct: action.payload,
            };
        case ProductDashboardActionTypes.SET_PRODUCT_LIST:
            return {
                ...state,
                productList: action.payload,
            };
        default:
            return state;
    }
}

export default ProductDashboard;