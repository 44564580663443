import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import AgentDashboard from "./dashboard/agent/reducers";
import SellerDashboard from "./dashboard/seller/reducers";
import BuyerDashboard from "./dashboard/buyer/reducers";
import ProductDashboard from "./dashboard/product/reducers";
import VarietyDashboard from "./dashboard/variety/reducers";
import CountryDashboard from "./dashboard/country/reducers";
import OrderDashboard from "./dashboard/order/reducers";
import OrderVendorDashboard from "./dashboard/orderVendor/reducers";

export default combineReducers({
  Auth,
  Layout,
  AgentDashboard,
  SellerDashboard,
  BuyerDashboard,
  ProductDashboard,
  VarietyDashboard,
  CountryDashboard,
  OrderDashboard,
  OrderVendorDashboard,
});
