import {BuyerDashboardActionTypes, BuyerDashboardStateTypes} from "./constants";
import {IBuyer, IAddress, IActiveModal} from "../../../classes/all";
import {BuyerDashboardActionType} from "./actions";

const INIT_STATE: BuyerDashboardStateTypes = {
    activeModalId: {} as IActiveModal, 
    currentBuyer: {} as IBuyer,
    currentAddress: {} as IAddress,
    buyerList: [] as IBuyer[],
    buyerCache: {} as {
        [key: number]: IBuyer
    }
};

const BuyerDashboard = (
    state: BuyerDashboardStateTypes = INIT_STATE,
    action: BuyerDashboardActionType<string>
) => {
    switch (action.type) {
        case BuyerDashboardActionTypes.SET_ACTIVE_MODAL_ID:
            return {
                ...state,
                activeModalId: action.payload,
            };
        case BuyerDashboardActionTypes.SET_CURRENT_BUYER:
            return {
                ...state,
                currentBuyer: action.payload,
            };
        case BuyerDashboardActionTypes.SET_CURRENT_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload,
            };
        case BuyerDashboardActionTypes.SET_BUYER_LIST:
            return {
                ...state,
                buyerList: action.payload,
            };
        case BuyerDashboardActionTypes.SET_BUYER_CACHE:
            return {
                ...state,
                buyerCache: action.payload,
            };
        default:
            return state;
    }
}

export default BuyerDashboard;