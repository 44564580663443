import {
    IActiveModal, 
    IOrder, 
    IOrderStep,
    IOrderStepFlow,
    IOrderStepType,
    IOrderStepVendorAssignment,
    ISeller, 
    IBuyer
} from "../../../classes/all";

enum OrderDashboardActionTypes {
    SET_ACTIVE_MODAL_ID = "@@orderDashboard/SET_ACTIVE_MODAL_ID",
    SET_CURRENT_SELLER = "@@orderDashboard/SET_CURRENT_SELLER",
    SET_CURRENT_BUYER = "@@orderDashboard/SET_CURRENT_BUYER",
    SET_ORDER_LIST = "@@orderDashboard/SET_ORDER_LIST",
    SET_CURRENT_ORDER = "@@orderDashboard/SET_CURRENT_ORDER",
    SET_ORDER_STEP_LIST = "@@orderDashboard/SET_ORDER_STEP_LIST",
    SET_ORDER_STEP = "@@orderDashboard/SET_ORDER_STEP",
    SET_ORDER_STEP_FLOW_LIST = "@@orderDashboard/SET_ORDER_STEP_FLOW_LIST",
    SET_ORDER_STEP_FLOW = "@@orderDashboard/SET_ORDER_STEP_FLOW",
    SET_ORDER_STEP_FLOW_CACHE = "@@orderDashboard/SET_ORDER_STEP_FLOW_CACHE",
    SET_ORDER_STEP_TYPE_LIST = "@@orderDashboard/SET_ORDER_STEP_TYPE_LIST",
    SET_ORDER_STEP_TYPE = "@@orderDashboard/SET_ORDER_STEP_TYPE",
    SET_ORDER_STEP_VENDOR_ASSIGNMENT_LIST = "@@orderDashboard/SET_ORDER_STEP_VENDOR_ASSIGNMENT_LIST",
    SET_ORDER_STEP_VENDOR_ASSIGNMENT = "@@orderDashboard/SET_ORDER_STEP_VENDOR_ASSIGNMENT",
}

export interface OrderDashboardStateTypes {
    activeModalId: IActiveModal;
    currentSeller: ISeller;
    currentBuyer: IBuyer;
    orderList: IOrder[];
    currentOrder: IOrder;
    orderStepList: IOrderStep[];
    currentOrderStep: IOrderStep;
    orderStepFlowList: IOrderStepFlow[];
    currentOrderStepFlow: IOrderStepFlow;
    orderStepFlowCache: {[key: number]: IOrderStepFlow[]};
    orderStepTypeList: IOrderStepType[];
    currentOrderStepType: IOrderStepType;
    orderStepVendorAssignmentList: IOrderStepVendorAssignment[];
    currentOrderStepVendorAssignment: IOrderStepVendorAssignment;
}

export {OrderDashboardActionTypes}