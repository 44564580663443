
import {VarietyDashboardActionTypes, VarietyDashboardStateTypes} from "./constants";

import {IVariety} from "../../../classes/all";

import {VarietyDashboardActionType} from "./actions";

const INIT_STATE: VarietyDashboardStateTypes = {
    currentVariety: {} as IVariety,
    varietyList: [] as IVariety[],
};

const VarietyDashboard = (
    state: VarietyDashboardStateTypes = INIT_STATE,
    action: VarietyDashboardActionType<string>
) => {
    switch (action.type) {
        case VarietyDashboardActionTypes.SET_CURRENT_VARIETY:
            return {
                ...state,
                currentVariety: action.payload,
            };
        case VarietyDashboardActionTypes.SET_VARIETY_LIST:
            return {
                ...state,
                varietyList: action.payload,
            };
        default:
            return state;
    }
}

export default VarietyDashboard;