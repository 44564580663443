import {ICountry} from "../../../classes/all";

enum CountryDashboardActionTypes {   
    SET_CURRENT_COUNTRY = "@@countryDashboard/SET_CURRENT_COUNTRY",
    SET_COUNTRY_LIST = "@@countryDashboard/SET_COUNTRY_LIST"
}

export interface CountryDashboardStateTypes {
    currentCountry: ICountry;
    countryList: ICountry[];
}

export {CountryDashboardActionTypes}