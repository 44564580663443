
import {CountryDashboardActionTypes, CountryDashboardStateTypes} from "./constants";

import {ICountry} from "../../../classes/all";

import {CountryDashboardActionType} from "./actions";

const INIT_STATE: CountryDashboardStateTypes = {
    currentCountry: {} as ICountry,
    countryList: [] as ICountry[],
};

const CountryDashboard = (
    state: CountryDashboardStateTypes = INIT_STATE,
    action: CountryDashboardActionType<string>
) => {
    switch (action.type) {
        case CountryDashboardActionTypes.SET_CURRENT_COUNTRY:
            return {
                ...state,
                currentCountry: action.payload,
            };
        case CountryDashboardActionTypes.SET_COUNTRY_LIST:
            return {
                ...state,
                countryList: action.payload,
            };
        default:
            return state;
    }
}

export default CountryDashboard;