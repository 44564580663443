import {OrderVendorDashboardActionTypes, OrderVendorDashboardStateTypes} from "./constants";
import {IOrderVendor, IAddress, IActiveModal} from "../../../classes/all";
import {OrderVendorDashboardActionType} from "./actions";

const INIT_STATE: OrderVendorDashboardStateTypes = {
    activeModalId: {} as IActiveModal, 
    currentOrderVendor: {} as IOrderVendor,
    currentAddress: {} as IAddress,
    orderVendorList: [] as IOrderVendor[],
};

const OrderVendorDashboard = (
    state: OrderVendorDashboardStateTypes = INIT_STATE,
    action: OrderVendorDashboardActionType<string>
) => {
    switch (action.type) {
        case OrderVendorDashboardActionTypes.SET_ACTIVE_MODAL_ID:
            return {
                ...state,
                activeModalId: action.payload,
            };
        case OrderVendorDashboardActionTypes.SET_CURRENT_ORDER_VENDOR:
            return {
                ...state,
                currentOrderVendor: action.payload,
            };
        case OrderVendorDashboardActionTypes.SET_CURRENT_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload,
            };
        case OrderVendorDashboardActionTypes.SET_ORDER_VENDOR_LIST:
            return {
                ...state,
                orderVendorList: action.payload,
            };
        default:
            return state;
    }
}

export default OrderVendorDashboard;