import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
// import Root from './Root';

// lazy load all the views

const Login = React.lazy(() => import("../pages/auth/Login"));

const Dashboard1 = React.lazy(() => import("../pages/dashboard/Dashboard1"));
const SellerDashboard = React.lazy(() => import("../pages/seller/SellerDashboard"));
const AgentDashboard = React.lazy(() => import("../pages/agent/AgentDashboard"));
const BuyerDashboard = React.lazy(() => import("../pages/buyer/BuyerDashboard"));
const ProductDashboard = React.lazy(() => import("../pages/product/ProductDashboard"));
const OrderDashboard = React.lazy(() => import("../pages/order/dashboard"));
const OrderVendorDashboard = React.lazy(() => import("../pages/order/vendor"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  }
];

const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/main" />,
      route: PrivateRoute,
    },
    {
      path: "/main",
      name: "Landing",
      element: <Dashboard1 />,
      route: PrivateRoute,
    },
    {
      path: "/orders",
      name: "Orders",
      element: <OrderDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/orders/vendors",
      name: "Vendors",
      element: <OrderVendorDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/sellers",
      name: "Sellers",
      element: <SellerDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/buyers",
      name: "Buyers",
      element: <BuyerDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/products",
      name: "Products",
      element: <ProductDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/agents",
      name: "Agents",
      element: <AgentDashboard />,
      route: PrivateRoute,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
];

const publicRoutes = [...authRoutes];
const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes
};
